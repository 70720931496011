<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="2" title="Workflow Step" icon="" />

    <div class="generalBox shadow-sm border">
        <div class="row">
            <div class="col-12 fw-bold mb-3">Configuration</div>

            <div class="col-lg-6 mb-2 text-primary">
                Agreement.pdf <span class="badge bg-secondary"><fa icon="object-ungroup" class="me-1"/>5</span>
            </div>

            <div class="col-lg-6 text-end mb-2">
                <span class="text-primary isLink" @click="$router.push({ path: '/workflow/doc' })">
                    <fa icon="file" class="me-2" />Document
                </span>
            </div>

            <div class="col-12">
                <ul class="nav nav-pills">
                    <li class="nav-item">
                        <a class="nav-link" :class="curTab === 'running' ? 'active' : ''" data-bs-toggle="pill" href="#running" @click="curTab = 'running'">Running No</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" :class="curTab === 'attachment' ? 'active' : ''"  data-bs-toggle="pill" href="#attachment" @click="curTab = 'attachment'">Attachment</a>
                    </li>
                </ul>

                <div class="tab-content mt-4">
                    <!-- Running No tab -->
                    <div class="tab-pane container p-0" :class="curTab === 'running' ? 'active' : ''" id="running">
                        <div class="row">
                            <div class="col-12 mb-2 fw-bold">Set running number for your workflow document.</div>
                            <div class="col-lg-5">
                                <div class="mb-2">Select and continue with existing running number</div>
                                <table class="table table-hover">
                                    <tbody>
                                        <tr v-for="r in arrRunning" :key="r">
                                            <td width="10%"><input type="radio" v-model="runningId" :value="r.prefix" /></td>
                                            <td>{{ r.prefix }}</td>
                                            <td>{{ r.number }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-lg-2 text-center text-middle">OR</div>
                            <div class="col-lg-5">
                                <div class="generalContentBox border">
                                    <div class="row">
                                        <div class="col-12 mb-3">
                                            <input type="radio" v-model="isNewRunningNo" value="true" class="me-2" />Create new running number
                                        </div>

                                        <div class="col-12 mb-2">
                                            <div class="form-floating">
                                                <input type="text" class="form-control" v-model="runPrefix" id="runPrefix" placeholder="Prefix">
                                                <label for="runPrefix">Prefix</label>
                                            </div>
                                        </div>

                                        <div class="col-12 mb-2">
                                            <div class="form-floating">
                                                <input type="text" class="form-control" v-model="runInitialNo" id="runInitialNo" placeholder="Initial Number">
                                                <label for="runInitialNo">Initial Number</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 mb-2"><hr /></div>
                            <div class="col-12 mb-2 text-end">
                                <button type="button" class="btn btn-secondary">
                                    <fa icon="floppy-disk" class="me-2" />Save
                                </button>
                            </div>

                        </div>
                    </div>

                    <!-- Attachment tab -->
                    <div class="tab-pane container p-0" :class="curTab === 'attachment' ? 'active' : 'fade'" id="attachment">
                        <div class="row">
                            <div class="col-12 mb-2">
                                <table border="0">
                                    <tbody>
                                        <tr>
                                            <td class="pt-1">
                                                <div class="form-check form-switch">
                                                    <input class="form-check-input" type="checkbox" v-model="allowAttachment" value="true" id="eSignee">
                                                </div>
                                            </td>
                                            <td class="ps-2">Allow signee to upload attachment when approve/reject a document</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-12 mb-2"><hr /></div>
                            <div class="col-12 mb-2 text-end">
                                <button type="button" class="btn btn-secondary">
                                    <fa icon="floppy-disk" class="me-2" />Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
</template>

<script>
import { ref, inject, onMounted, watch, reactive } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import { useRoute, useRouter } from 'vue-router'
import Alert from '@/components/Alert.vue'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'

export default {
    name: 'WorkFlowDocConfig',
    components: { TopNavigation, Alert, Popper },
    setup () {
        const alert = ref([])

        const axios = inject('axios')
        const route = useRoute()
        const router = useRouter()
        const func = funcs

        const curTab = ref('running')
        const runningId = ref(null)
        const arrRunning = ref([])
        const isNewRunningNo = ref(false)
        const runPrefix = ref(null)
        const runInitialNo = ref(null)
        const allowAttachment = ref(false)

        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        onMounted(() => {
            arrRunning.value = [
                {
                    "prefix": "DocId",
                    "number": ""
                },
                {
                    "prefix": "CR_",
                    "number": "23000"
                }
            ]
        })

        return { 
            route, router, alert, closeAlert, curTab, runningId, arrRunning, isNewRunningNo, runPrefix, runInitialNo, allowAttachment
        }

    }
}
</script>

<style>
</style>